import { Routes } from '@angular/router';
import { EditAddressPageComponent } from '@checkout/shared/pages/edit-address-page/edit-address-page.component';
import { ViewAddressPageComponent } from '@checkout/shared/pages/view-address/view-address-page.component';
import { PaymentMethodPageComponent } from '@checkout/shared/pages/payment-method-page/payment-method-page.component';
import { LinkAnotherPageComponent } from '@checkout/shared/pages/link-another-page/link-another-page.component';
import { CheckoutGuard } from './checkout.guard';
import { PilotRedirectGuard } from '@pilot/guards/pilot-redirect.guard';
import { FormCanDeactivateGuard } from './form-can-deactivate.guard';
import { withChipAndLogo } from '@core/constants/headerConfigs';
import { FiRedirectComponent } from '@core/components/fi-redirect/fi-redirect.component';
// checkout routes to initialize merchant Window service
export const UpdateReviewRoutes: Routes = [
  {
    path: 'address',
    pathMatch: 'full',
    component: ViewAddressPageComponent,
    data: {
      pageTitleKey: 'COMMON.UPDATE.PAGE.TITLE.SHIPPING_ADDRESS',
      enableBackButton: false,
      secondaryBackButton: true,
      stepData: {
        showStepInfo: false,
      },
      showAmountChip: true,
    },
  },
  {
    path: 'address/edit/:id',
    component: EditAddressPageComponent,
    data: {
      enableBackButton: false,
      secondaryBackButton: true,
      stepData: {
        showStepInfo: false,
      },
      showAmountChip: true,
      backUrl: '../..',
      editPage: true,
      pageTitleKey: 'COMMON.UPDATE.PAGE.TITLE.EDIT_SHIPPING_ADDRESS',
    },
    canDeactivate: [FormCanDeactivateGuard],
  },
  {
    path: 'address/edit',
    component: EditAddressPageComponent,
    data: {
      pageTitleKey: 'COMMON.UPDATE.PAGE.TITLE.EDIT_SHIPPING_ADDRESS',
      enableBackButton: false,
      secondaryBackButton: true,
      stepData: {
        showStepInfo: false,
      },
      showAmountChip: true,
      backUrl: '../..',
    },
    canDeactivate: [FormCanDeactivateGuard],
  },
  {
    path: 'paymentMethod',
    pathMatch: 'full',
    component: PaymentMethodPageComponent,
    data: {
      pageTitleKey: 'COMMON.UPDATE.PAGE.TITLE.PAYMENT_METHOD',
      onPopState: true,
      showAmountChip: true,
    },
  },
  {
    path: 'paymentMethod/linkBank',
    pathMatch: 'full',
    component: LinkAnotherPageComponent,
    data: {
      pageTitleKey: 'COMMON.UPDATE.PAGE.TITLE.LINK_BANK',
      enableBackButton: false,
      secondaryBackButton: false,
      stepData: {
        showStepInfo: false,
      },
      showAmountChip: true,
      onPopState: true,
    },
  },
  {
    path: 'paymentMethod/linkBank/fi-redirect',
    component: FiRedirectComponent,
    data: {
      pageTitleKey: 'COMMON.AUTH.PAGE.TITLE.FI_SELECTION',
      ...withChipAndLogo,
      secondaryBackButton: true,
    },
  },
  {
    path: 'address/add',
    component: EditAddressPageComponent,
    data: {
      showHeader: true,
      backUrl: '..',
      pageTitleKey: 'COMMON.UPDATE.PAGE.TITLE.ADD_SHIPPING_ADDRESS',
      enableBackButton: false,
      secondaryBackButton: true,
      stepData: {
        showStepInfo: false,
      },
      showAmountChip: true,
    },
    canDeactivate: [FormCanDeactivateGuard],
  },
];

export const routes: Routes = [
  {
    path: 'user-portal',
    loadChildren: () => import('@user-portal/user-portal.module').then((m) => m.UserPortalModule),
  },
  {
    path: 'checkout',
    loadChildren: () => import('@checkout/checkout.module').then((m) => m.CheckoutModule),
    canActivate: [CheckoutGuard],
  },
  {
    path: 'email-verification',
    loadChildren: () => import('@email-confirmation/email-confirmation.module').then((m) => m.EmailConfirmationModule),
  },
  { path: 'error', loadChildren: () => import('@error/error.module').then((m) => m.ErrorModule) },
  {
    path: 'pilot',
    loadChildren: () => import('@pilot/pilot.module').then((m) => m.PilotModule),
  },
  {
    path: '',
    loadChildren: () => import('@error/error.module').then((m) => m.ErrorModule),
    pathMatch: 'full',
    canActivate: [PilotRedirectGuard],
  },
  // Wildcard route for a 404 page
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
