<div class="content_main-container">
  <ng-container *ngrxLet="defaultSavingAccountId$ as defaultSavingAccountId">
    <ng-container *ngIf="!isGuestCheckout; else guestCheckout">
      <h1 id="payment-page-title">
        {{ (isOrderReview ? '' : 'CHECKOUT.CREATE_PROFILE.PAYMENT_METHOD.TITLE') | translate }}
      </h1>
      <div *ngIf="!isOrderReview">
        <small class="mat-body-2 block mt-11" id="checkout_payment-method_subtitle">{{
          'CHECKOUT.CREATE_PROFILE.PAYMENT_METHOD.SUBTITLE' | translate
        }}</small>
      </div>
      <div *ngIf="isReturningUser">
        <pbb-link-bank *ngrxLet="email$ as email" [email]="email"></pbb-link-bank>
      </div>
    </ng-container>
    <ng-template #guestCheckout>
      <div class="mt-28 flex-row flex-jc-none">
        <button
          *ngIf="continueToReview"
          mat-icon-button
          class="back-btn"
          [attr.aria-label]="'ARIA_LABEL.BACK' | translate"
          id="header_back-btn"
          routerLink=".."
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
        <h1 id="checkout_continue-payment-method-title">
          {{ 'CHECKOUT.CONTINUE.PAYMENT_METHOD.TITLE' | translate }}
        </h1>
      </div>
      <small class="mat-body-2 mt-11 block mb-32">{{ 'CHECKOUT.CONTINUE.PAYMENT_METHOD.SUBTITLE' | translate }}</small>
    </ng-template>
    <div class="notifications">
      <pbb-notice
        *ngIf="errorMessage"
        id="order-review_payment-method-error-message"
        [type]="noticeType.ERROR"
        [title]="errorMessage"
        [fullTitle]="fullErrorMessage"
      ></pbb-notice>
    </div>
    <ng-container *ngrxLet="defaultFiId$ as defaultFiId">
      <ng-container *ngrxLet="defaultAccountId$ as defaultAccountId">
        <div class="content_main-fi-group" *ngrxLet="linkedFIs$ as linkedFis">
          <pbb-user-account-selector
            id="payment-method-account-selector"
            [linkedFis]="linkedFis"
            [showAsAccordion]="true"
            [showSetDefault]="!isGuestCheckout && isReturningUser"
            [(ngModel)]="selectedAccount"
            [defaultAccountId]="defaultAccountId"
            [defaultSavingAccountId]="defaultSavingAccountId"
            (setDefault)="setDefaultPaymentMethod($event)"
            (ngModelChange)="updatePaymentMethod($event)"
          >
          </pbb-user-account-selector>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <div *ngIf="isReturningUser">
    <pbb-link-bank-button></pbb-link-bank-button>
  </div>
  <div class="content_main-button-list mt-32">
    <button id="btn-continue" mat-flat-button color="primary" [disabled]="!selectedAccount" (click)="onClickContinue()">
      {{ 'COMMON.BUTTON.CONTINUE' | translate }}
    </button>
  </div>
</div>
